<template>
	<div>
		<div class='cu-header flex align-center justify-between'>
			<div class='flex align-center margin-left-20'>
				<div class='company-box cursor flex align-center'>
					<!--        <div class="logo">-->
					<!--          <img src="/avatar.jpeg" alt="">-->
					<!--        </div>-->
					<span class='margin-left-5 text-20 text-cut' style='width: 400px;'>
          				{{ user && user['company_name'] !== '' ? user['company_name'] : '超级平台端' }}
        			</span>
				</div>
			</div>
			<div class='flex align-center margin-right-20'>
				<div class='margin-right-25 cursor'>
					<el-badge :value='0' :is-dot='true' v-if='0'>
						<i class='iconfont icon-tixing text-20 text-hover-pink'></i>
					</el-badge>
					<i class='iconfont icon-tixing text-20 text-hover-pink' v-if='!0'></i>
				</div>
				<!--      <div class="margin-right-25 cursor" @click.stop="toFullScreen()">-->
				<!--        <i class="iconfont icon-quanping text-white text-18 text-hover-yellow" v-if="!fullscreen"></i>-->
				<!--        <i class="iconfont icon-xiaoping02 text-white text-18 text-hover-yellow" v-if="fullscreen"></i>-->
				<!--      </div>-->

				<div class='flex align-center cursor user-box'>
					<div class='avatar cursor'>
						<img v-if="user && user['avatar'] !== ''" :src="baseUrl+user['avatar']" alt=''>
						<img v-else src='/avatar.jpeg' alt=''>
					</div>
					<div v-if='user' class='text-center name-box'>
						<div>{{ user['name'] }}</div>
						<div class='text-12'>{{ user['roles_name'] }}</div>
					</div>
					<div class='user-btn text-black-0'>
						<div class='user-btn-item solid-bottom flex align-center justify-center'
							 @click.stop='handleChangePassword()'>
							<i class='iconfont icon-zhanghaoquanxian margin-right-5'></i>
							<span>修改密码</span>
						</div>
						<div class='user-btn-item flex align-center justify-center' @click.stop='logout()'>
							<i class='iconfont icon-chuchang margin-right-5'></i>
							<span>退出系统</span>
						</div>
					</div>
				</div>
			</div>
		</div>

		<cu-dialog :options='passOption' @close='passOption.show = false' @confirm='passConfirm()'>
			<el-form :model='passwordForm' label-width='80' label-position='right'>
				<el-form-item label='旧密码'>
					<el-input type='password' v-model='passwordForm.old_pwd'></el-input>
				</el-form-item>
				<el-form-item label='新密码'>
					<el-input type='password' v-model='passwordForm.new_pwd'></el-input>
				</el-form-item>
				<el-form-item label='确认密码'>
					<el-input type='password' v-model='passwordForm.con_pwd'></el-input>
				</el-form-item>
			</el-form>
		</cu-dialog>
	</div>
</template>

<script>
import CuDialog from '@/components/common/cu-dialog';
import { err_alert } from '@/utils/common';
import api from '@/api';
import { getBaseUrl } from '@/utils/config';

export default {
	name: 'cu-header',
	components: { CuDialog },
	computed: {
		user() {
			return this.$store.getters['basic/get_user'];
		}
	},
	data() {
		return {
			fullscreen: false,
			showUserBtn: false,
			passOption: {
				show: false,
				title: '修改密码',
				width: '300px'
			},
			passwordForm: {
				old_pwd: '',
				new_pwd: '',
				con_pwd: ''
			},
			baseUrl: getBaseUrl()
		};
	},
	methods: {
		toFullScreen() {
			let element = document.documentElement;
			if (this.fullscreen) {
				if (document.exitFullscreen) {
					document.exitFullscreen();
				} else if (document.webkitCancelFullScreen) {
					document.webkitCancelFullScreen();
				} else if (document.mozCancelFullScreen) {
					document.mozCancelFullScreen();
				} else if (document.msExitFullscreen) {
					document.msExitFullscreen();
				}
			} else {
				if (element.requestFullscreen) {
					element.requestFullscreen();
				} else if (element.webkitRequestFullScreen) {
					element.webkitRequestFullScreen();
				} else if (element.mozRequestFullScreen) {
					element.mozRequestFullScreen();
				} else if (element.msRequestFullscreen) {
					// IE11
					element.msRequestFullscreen();
				}
			}
			this.fullscreen = !this.fullscreen;
		},
		logout() {
			this.$router.push({ path: '/login' });
		},
		switchCompany() {
			this.$router.push({ path: '/welcome' });
		},
		handleChangePassword() {
			this.passwordForm.old_pwd = '';
			this.passwordForm.new_pwd = '';
			this.passwordForm.con_pwd = '';
			this.passOption.show = true;
		},
		passConfirm() {
			if (this.passwordForm.old_pwd === '' || this.passwordForm.new_pwd === '' || this.passwordForm.con_pwd === '') {
				return false;
			}
			if (this.passwordForm.new_pwd !== this.passwordForm.con_pwd) {
				err_alert('新密码两次输入不一致,请重输入');
				return false;
			}
			let self = this;
			api.user.changePassword(this.passwordForm).then(res => {
				if (res.code === 0) {
					self.passOption.show = false;
					setTimeout(() => {
						self.logout();
					}, 600);
				}
			});
		}
	}
};
</script>

<style lang='scss' scoped>
@import "../../assets/layout";

.user-box {
	.user-btn {
		display: none;
	}
}

.user-box:hover {
	height: 130px;

	.avatar {
		border-bottom-color: #5e00ff;
	}

	.name-box {
		color: #5e00ff;
	}

	.user-btn {
		top: 91px;
		display: block;
	}

}
</style>