export default [
	{
		path: 'basic/supplier/list',
		name: 'supplier-list',
		meta: {
			title: '承运商列表',
			hidden: false
		},
		component: () => import('@/views/basic/supplier-list')
	},
	{
		path: 'basic/custom/list',
		name: 'custom-list',
		meta: {
			title: '客户列表',
			hidden: false
		},
		component: () => import('@/views/basic/custom-list')
	},
	{
		path: 'basic/custom/price',
		name: 'custom-price',
		meta: {
			title: '客户价格',
			hidden: false
		},
		component: () => import('@/views/basic/custom-price')
	},
	{
		path: 'basic/driver/list',
		name: 'driver-list',
		meta: {
			title: '司机管理',
			hidden: false,
			keepAlive: true
		},
		component: () => import('@/views/basic/driver-list')
	},
];