export default [
	{
		path: '/home',
		name: 'home',
		meta: {
			title: '首页',
			hidden: true
		},
		component: () => import('@/views/home/HomeView.vue')
	},
	{
		path: '/notFound',
		name: 'notFound',
		meta: {
			title: 'notFound',
			hidden: false
		},
		component: () => import('@/views/system/NotFound')
	},
	{
		path: '/roles/set',
		name: 'roles-set',
		meta: {
			title: '角色管理',
			hidden: false
		},
		component: () => import('@/views/system/RolesSet')
	},
	{
		path: '/menu/set',
		name: 'menu-set',
		meta: {
			title: '菜单管理',
			hidden: false
		},
		component: () => import('@/views/system/MenuSet')
	},
	{
		path: '/admin/list',
		name: 'admin-list',
		meta: {
			title: '管理员列表',
			hidden: false
		},
		component: () => import('@/views/system/admin-list')
	},
	{
		path:'/user/list',
		name:'user-list',
		meta: {
			title: '用户列表',
			hidden: false
		},
		component: () => import('@/views/system/user-list')
	},
	{
		path: '/company/expense',
		name: 'company-expense',
		meta: {
			title: '费用中心',
			hidden: false
		},
		component: () => import('@/views/system/company-expense')
	},
	{
		path:'/company/list',
		name:'company-list',
		meta: {
			title:'企业列表',
			hidden: false
		},
		component: () =>  import('@/views/system/company-list')
	},
	{
		path: '/banner/list',
		name: 'banner-list',
		meta: {
			title: '轮播图管理',
			hidden: false
		},
		component: () => import('@/views/system/banner-list')
	}
];