export default [
	{
		path: '/order/list',
		name: 'order-list',
		meta: {
			title: '订单列表',
			hidden: false
		},
		component: () => import('@/views/order/order-list')
	},
	{
		path: '/order/sales',
		name: 'order-sales',
		meta: {
			title: '我的订单',
			hidden: false
		},
		component: () => import('@/views/order/order-sales')
	},
	{
		path: '/order/change',
		name: 'order-change',
		meta: {
			title: '订单修改',
			hidden: false,
			keepAlive: true
		},
		component: () => import('@/views/order/order-change')
	},
	{
		path: '/order/change-review',
		name: 'order-change-review',
		meta: {
			title: '改单审核',
			hidden: false
		},
		component: () => import('@/views/order/order-change-review')
	},
];