export default [
	{
		path: '/expense/custom',
		name: 'expense-custom',
		meta: {
			title: '客户对账',
			hidden: false
		},
		component: () => import('@/views/expense/expense-custom')
	},
	{
		path: '/plat/expense/po',
		name: 'plat-expense-po',
		meta: {
			title: '付承运费用',
			hidden: false
		},
		component: () => import('@/views/expense/plat-expense-po')
	},
	{
		path: '/plat/expense/so',
		name: 'plat-expense-so',
		meta: {
			title: '收开单费用',
			hidden: false
		},
		component: () => import('@/views/expense/plat-expense-so')
	},
];