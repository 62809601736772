const RUN_SERVER = 'local'; //开发环境变量

export const getBaseUrl = function () {
	let url = '';
	if (process.env.NODE_ENV === 'production') {
		url = 'https://nd-api.56site.com';
		// url = 'https://dev-api.56site.com';
	} else {
		switch (RUN_SERVER) {
			// case "local" : url = 'https://dev-api.56site.com';break;
			case 'local':
				url = 'http://127.0.0.1:9797';
				break;
			case 'pro':
				url = 'http://global56-api.kexikui.com';
				break;
			case 'test':
				url = 'https://nd-api.56site.com';
				break;
		}
	}
	return url;
};
